<template>
  <div class="real-estate-contracts">
    <!--Filters-->
    <div class="table-filter" v-bind:class="{'sticky':stickyFilter}">
      <b-card no-body>
        <b-card-header class="pb-50">
          <b-row no-gutters class="w-100">
            <b-col>
              <h5>{{ msg("Filters") }}</h5>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-link class="mr-1" v-bind:class="{'text-muted':!stickyFilter}" style="color: inherit"
                        @click="stickyFilter = !stickyFilter">
                  <i class="fa-solid fa-thumbtack"></i>
                </b-link>
                <b-link class="mr-1" style="color: inherit" @click="resetFilters">
                  <feather-icon icon="XIcon" size="16"/>
                </b-link>
                <b-link style="color: inherit" @click="refresh()">
                  <feather-icon icon="RefreshCcwIcon" size="16"/>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Id -->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ msg("Id") }}</label>
              <b-form-input
                  size="sm"
                  v-model="filter.like.id"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <b-col cols="12" md="3" lg="3" class="mb-md-0 mb-2">

              <b-form-group :label="msg('Status')" label-for="realEstate-branch">
                <v-select

                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.in.status"
                    :options="allContractStatus"
                    class="small w-100"
                    label="text"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>
            <!--Buy/Rent-->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <b-form-group :label="msg('EndDate')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="temp"
                    :options="[
                        {value:getUnderYear,label:msg('Under 1 year')},
                        {value:getBetween1And2,label:msg('Between 1 and 2 years')},
                        {value:over2Years,label:msg('Over 2 years')}
                        ]"
                    class="w-100 small"
                    label="label"
                    :reduce="(branch) => branch.value"
                />
              </b-form-group>
            </b-col> <!--Buy/Rent-->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Platform')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="filter.eq.bnbStyleRent.platform"
                    :options="[
                        {value:'Booking',label:('Booking')},
                        {value:'Airbnb',label:('Airbnb')},
                        {value:'Treasure Home',label:('Treasure Home')},
                        {value:'Expedia',label:('Expedia')},
                        {value:'Vrbo',label:('Vrbo')},
                        {value:'Agoda',label:('Agoda')},
                        {value:null,label:('All')}
                        ]"
                    :clearable="false"

                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100 small"

                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="3">

              <b-form-group :label="$t('Real estate')" label-for="realEstate-branch">
                <real-estate-picker small v-model="filter.eq.propertyId"/>
              </b-form-group>
            </b-col>
            <!-- Id -->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ msg("Firstname") }}</label>
              <b-form-input
                  size="sm"
                  v-model="filter.eq.client.firstname"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Id -->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ msg("Lastname") }}</label>
              <b-form-input
                  size="sm"
                  v-model="filter.eq.client.lastname"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Id -->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ msg("Email") }}</label>
              <b-form-input
                  size="sm"
                  v-model="filter.eq.client.email"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Id -->
            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <label>{{ msg("External id") }}</label>
              <b-form-input
                  size="sm"
                  v-model="filter.eq.platformReservationId"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <b-col md="5" class="">
              <b-form-group :label="msg('checkinrange')" label-for="realEstate-branch">
                <calendar-picker-range size="sm" v-model="datesRange"/>
              </b-form-group>

            </b-col>
            <b-col md="2" class="">
              <b-form-group label="Reset check-in filter" label-for="realEstate-branch">
                <b-button variant="danger" size="sm" @click="datesRange=null">
                  <i class="fas fa-arrow-circle-left"></i> Reset
                </b-button>
              </b-form-group>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Rent contracts') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">

                <!--                <b-button class="mr-1" variant="primary" @click="onContractGenerate()">
                                  <span class="text-nowrap">{{ msg('Generate contract from crm') }}</span>
                                </b-button>-->
                <!--
                                <b-button disabled variant="primary" @click="onTreasureContractGenerate()">
                                  <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
                                </b-button>
                -->

                <b-button variant="danger" class="mr-2" @click="onBlockCalendar()">
                  <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
                </b-button>
                <b-button variant="primary" class="mr-2" @click="onTreasureContractGenerateMulti()">
                  <span class="text-nowrap">{{ msg('Create contract') }}</span>
                </b-button>

                <!--                <b-button variant="dark" @click="onTreasureContractGenerate()">
                                  <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
                                </b-button>-->
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <div v-handy-scroll class="table-responsive">
          <b-table
              ref="contracts-table"
              striped
              hover
              v-bind:items="contracts"
              v-bind:fields="fields"
              :busy="loading"
              @sort-changed="onSort"
              @row-clicked="onRowClick"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mx-1"></b-spinner>
                <strong>{{ msg('Loading') }}...</strong>
              </div>
            </template>
            <template #cell(creationTime)="data">
              <div>
                {{ $moment(data.value).format('DD/MM/YY HH:mm') }}
              </div>
            </template>
            <template #cell(id)="data">
              <b-button variant="outline-transparent" size="sm" @click="openSideBar(data.value)">
                <b-img src="../../../assets/images/icons/eye.svg" alt="sms" style="width: 30px; height: 30px;"/>
              </b-button>
            </template>
            <template #cell(propertyId)="data">
              <div>
                <div v-if="realEstates[data.value] != null">
                  <b-img
                      v-bind=" { blank: (realEstates[data.value].attachments == null || realEstates[data.value].attachments[0] == null), blankColor: '#777', width: 50, height: 50,  }"
                      v-bind:src="getImageSrc(realEstates[data.value])"
                      blank-color="#475F7B"
                      rounded
                      alt="Rounded image"
                      class="d-inline-block m-1"
                  />
                  {{ realEstates[data.value].address }}
                  {{ realEstates[data.value].house }}/
                  {{ realEstates[data.value].apt }}
                  {{ realEstates[data.value].city }}
                </div>
              </div>
            </template>
            <template #cell(client)="data">
              <span>{{ data.value.firstname }} {{ data.value.lastname }}, {{ data.value.address.country }}</span>
            </template>
            <template #cell(bnbStyleRent)="data">
              <platform-logo v-if=" data.value.platform != null" v-bind:platform="data.value.platform">
                {{ data.value.platform }}
              </platform-logo>
            </template>
            <template #cell(moveDate)="data">
              <div class="d-flex align-items-center">
                <!-- Move Date -->
                <div class="mr-1">
                  <b-button
                      disabled
                      pill
                      size="sm"
                      variant="outline-success"
                      v-if="data.item.moveDate && data.item.moveDate !== ''"
                  >
                    {{ $moment(data.item.moveDate).format('DD.MM.YY') }}
                  </b-button>
                  <span v-else>{{ msg('Move Date Not Set Yet') }}</span>
                </div>

                <!-- End Date -->
                <div>
                  <b-button
                      disabled
                      pill
                      size="sm"

                      :variant="'outline-' + getEndDateColor(data.item.endDate)"
                      v-if="data.item.endDate && data.item.endDate !== ''"
                  >
                    {{ $moment(data.item.endDate).format('DD.MM.YY') }}
                  </b-button>
                  <span v-else>{{ msg('End Date Not Set Yet') }}</span>
                </div>
              </div>
            </template>


            <template #cell(alerts)="data">
              <div v-if="data.value != null">
                <b-progress height="20px" :variant="variantColorByProgress[getProgressValue(data.item)]"
                            :value="getProgressValue(data.item)" :max="100" show-progress animated striped></b-progress>

              </div>
            </template>

            <!-- Deposit Paid Cell -->
            <template #cell(depositPaid)="data">
              <div>
                <i
                    :class="{'text-success fas fa-check-circle': data.value,'text-danger fas fa-times-circle': !data.value}"
                    style="font-size: 1.5rem;"
                ></i>
              </div>
            </template>

            <!-- Deposit Refunded Cell -->
            <template #cell(depositRefunded)="data">
              <div>
                <i :class="{'text-success fas fa-check-circle': data.value,'text-danger fas fa-times-circle': !data.value}"
                    style="font-size: 1.5rem;"
                ></i>
              </div>
            </template>

            <!--            <template #cell(id)="data">
                          <b-button variant="outline-transparent" size="sm" @click="sendCheckInDetailsButtonSMS(data.item.id)">
                            <b-img src="../../../assets/images/icons/smsicon.png" alt="sms" style="width: 30px; height: 30px;"/>
                          </b-button>
                        </template>-->


            <template #cell(price)="data">
              <div v-if="data.item.bnbStyleRent!=null">
                {{ convertToEURO(data.item.bnbStyleRent.totalPayment) }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </template>

            <template #cell(status)="data">
              <div>
                <template v-if="data.value === 'ACTIVE'">
                  <b-badge href="#" variant="success">{{ msg(data.value) }}</b-badge>
                </template>
                <template v-else-if="data.value === 'CANCELED'">
                  <b-badge href="#" variant="danger">{{ msg(data.value) }}</b-badge>
                </template>
                <template v-else-if="data.value === 'REVIEWING'">
                  <b-badge href="#" variant="secondary">{{ msg(data.value) }}</b-badge>
                </template>
                <template v-else-if="data.value === 'NEW'">
                  <b-badge href="#" variant="warning">{{ msg(data.value) }}</b-badge>
                </template>
                <template v-else-if="data.value === 'INQUIRY'">
                  <b-badge href="#" variant="warning">{{ msg(data.value) }}</b-badge>
                </template>
                <template v-else>
                  <b-badge href="#" variant="info">{{ msg(data.value) }}</b-badge>
                </template>
              </div>
            </template>
          </b-table>
        </div>
        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.contract" size="xl" ref="realEstate-modal" id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <contract-rent-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="contractModal" title="Contracts" ok-only centered>
      <ul v-if="busyContracts!=null && busyContracts['id']!=null && busyContracts['id'].length>0">
        <li v-for="contract in busyContracts.server" :key="contract.id">
          <p>
            <b-link @click="goToContract(contract.id)">{{ msg('To the contract') }}</b-link>
          </p>
          <p>Address: {{ contract.propertyAddress }}</p>
          <p>Client: {{ contract.client.firstname + ' ' + contract.client.lastname }}</p>
          <p>Dates: {{
              $moment(contract.bnbStyleRent.start, 'YYYY-MM-DD').format("DD/MM/YYYY") + ' - ' + $moment(contract.bnbStyleRent.end, 'YYYY-MM-DD').format("DD/MM/YYYY")
            }}</p>
          <!-- Display other contract details here -->
        </li>
      </ul>
      <p v-else>{{ msg('No active bookings for given dates') }}</p>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.treasureContract" size="xl" ref="realEstate-modal"
             id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <treasure-contract-rent-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.treasureContractMulti" size="xl"
             ref="realEstate-modal"
             id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <treasure-contract-rent-panel-multi ref="realEstatePanel" @submitted="onSubmitted" @checked="contractToast"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-warning" class="btn-dialog mr-1" @click="checkAvailability">
          {{ msg('Check availability') }}
        </b-button>
        <b-button :disabled="savingContract" variant="outline-success" class="btn-dialog mr-1" @click="submitContract">
          {{ savingContract ? msg('Loading') : msg('Save') }}
        </b-button>
        <b-button :disabled="!savingContract" variant="outline-danger" class="btn-dialog" @click="resetButton">
          {{ msg('Reset') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal modal-class="modal-fullscreen" no-fade v-model="dialogs.blockCalendar" size="lg"
             ref="realEstate-modal"
             id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <block-dates-panel ref="blockPanel" @submitted="onSubmitted"/>


    </b-modal>
    <ContractRentSidebar ref="contractRentSidebar"/>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ContractRentPanel from "@/views/realEstate/contracts/contractRentPanel";
import TreasureContractRentPanel from "@/views/realEstate/contracts/treasureContractRentPanel";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";
import CalendarPickerRange from "@/components/widgets/calendar/calendarPickerRange";
import PlatformLogo from "@/components/widgets/PlatformLogo";
import TreasureContractRentPanelMulti from "@/views/realEstate/contracts/treasureContractRentPanelMulti";
import BlockDatesPanel from "@/views/realEstate/contracts/blockDatesPanel.vue";
import mixinFilter from "@/mixins/mixinFilter";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ContractRentSidebar from "@/components/sidebar/ContractRent/ContractRentSidebar.vue";

const rentContractFilter = () => ({
  like: {search: '', name: "", shortDescription: ""},
  eq: {type: "", client: {},over30Days:true, bnbStyleRent: {platform: null}},
  lte: {}, gte: {}, in: {}
});

export default {
  name: "RealEstateRentContracts",
  mixins: [mixinFilter],
  components: {
    ContractRentSidebar,
    BlockDatesPanel,
    ContractRentPanel,
    TreasureContractRentPanel,
    TreasureContractRentPanelMulti,
    RealEstatePicker,
    CalendarPickerRange,
    PlatformLogo

  },
  data() {
    return {
      datesRange: null,
      loading: true,
      busyContracts: null,
      savingContract: false,
      amount: 10,
      contracts: [],
      realEstates: {},
      dialogs: {contract: false, treasureContract: false, treasureContractMulti: false, blockCalendar: false},
      temp: {},
      filter: rentContractFilter(),
      stickyFilter: false,
      sort: {by: 'creationTime', desc: true, direction: 'desc'},
      page: {amount: 10, currentPage: 1, total: 0},
      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  watch: {

    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    }, datesRange: {
      handler() {
        this.refresh()
      }
    },
  },
  created() {
    let $this = this;
    this.filter = this.loadCachedFilters(rentContractFilter);
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('realEstateContracts', ['allStatus']),
    allContractStatus() {
      return this.allStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    getUnderYear() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');
    },
    getBetween1And2() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');

    },
    over2Years() {
      return this.$moment().add(1, 'year').format('YYYY-MM-DD');

    },

    variantColorByProgress() {
      return {
        100: 'success',
        75: 'warning',
        50: 'warning',
        25: 'danger',
        0: 'danger'

      };
    },
    fields() {
      let $this = this
      return [
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'id',
          label: 'View',
          sortable: true
        },
        {
          key: 'creationTime',
          label: $this.msg('Creation Time'),
          sortable: true
        },
        {
          key: 'propertyId',
          label: $this.msg('Address'),
          sortable: true
        },
        {
          key: 'bnbStyleRent',
          label: $this.msg('Platform'),
          sortable: true
        },
        {
          key: 'platformReservationId',
          label: $this.msg('Platform Id'),
          sortable: true
        },
        {
          key: 'client',
          label: $this.msg('Client'),
          sortable: false
        },
        /* {
           key: 'id',
           label: $this.msg('Check-in SMS'),
           sortable: false
         },*/
        {
          key: 'alerts',
          label: $this.msg('Progress'),
          sortable: false
        },
        {
          key: 'price',
          label: $this.msg('Rental price'),
          sortable: false
        },
        {
          key: 'moveDate',
          label: $this.msg('CheckIn - CheckOut'),
          sortable: false
        },


        /*   {
             key: 'endDate',
             label: $this.msg('Contract end date'),
             sortable: false
           },*/
        {
          key: 'numberOfGuests',
          label: $this.msg('numberOfGuests'),
          sortable: false
        },
        {
          key: 'depositPaid',
          label: $this.msg('Deposit paid'),
          sortable: true
        },
        {
          key: 'depositRefunded',
          label: $this.msg('Deposit refunded'),
          sortable: true
        }

      ]
    },
  },
  methods: {
    ...mapActions('realEstateContracts', ['getAllContracts', 'sendSMSCheckIn']),
    goToContract(id) {
      window.open(`/real_estate/contracts/rent/${id}`, '_blank');
    },
    openSideBar(id) {
      this.$refs.contractRentSidebar.showContract(id)
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    gradientColor(progress) {
      // Calculate color based on progress value (green to red gradient)
      const hue = ((1 - progress / 100) * 120).toString(10);
      return `hsl(${hue}, 100%, 50%)`;
    },
    getEndDateColor(endDate) {
      let today = this.$moment();
      let diffDays = this.$moment(endDate).diff(today, 'days');
      if (diffDays < 365)
        return "danger"
      else if (diffDays < 730)
        return "warning"
      return "success"

    },

    onContractGenerate() {
      this.dialogs.contract = true
    },
    onTreasureContractGenerateMulti() {
      this.dialogs.treasureContractMulti = true
    },
    onBlockCalendar() {
      this.dialogs.blockCalendar = true
    },
    onTreasureContractGenerate() {
      this.dialogs.treasureContract = true
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.filter.eq.over30Days = true
      if (this.datesRange != null) {
        this.filter.gte.moveDate = this.datesRange.start
        this.filter.lte.moveDate = this.datesRange.end
      } else {
        this.filter.gte.moveDate = null
        this.filter.lte.moveDate = null
      }
      this.getAllContracts({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'creationTime',
        sortby: 'desc',
      })
          .then(this.onContractsRetrieve, (error) => $this.error = error)
    },
    onContractsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.contracts = data.contracts.filter(contract => contract.status !== "INQUIRY");
      this.realEstates = (data.realEstates || []).toObject("id");
      this.loading = false;
    },
    getImageSrc(property) {
      if (property == null || property.attachments == null || property.attachments[0] == null)
        return null
      return (property.attachments[0].attachmentUrl.startsWith('http') ? '' : this.getRealEstatesWebSite) + property.attachments[0].attachmentUrl;
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },

    onRowClick(contract) {
      window.open(`/real_estate/contracts/rent/${contract.id}`, '_blank');
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)

    },
    submitContract() {
      this.savingContract = true;
      this.$root.$emit('crm::submitContract')
    },
    checkAvailability() {
      this.$root.$emit('crm::checkContract')

    },
    resetButton() {
      this.savingContract = false;
    },
    onSubmitted(ans) {
      if (ans.status) {
        this.refresh()

        this.dialogs.contract = false;
        this.dialogs.treasureContract = false;
        this.dialogs.treasureContractMulti = false;
        this.dialogs.blockCalendar = false;
        this.savingContract = false;

      }

    },
    contractToast(ans) {
      console.log("contractToast : ", ans);
      if (ans == null) {
        // No contracts
        this.$bvModal.msgBoxOk('No contracts on these dates', {
          title: 'No Contracts',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success'
        });
      } else {
        // Contracts found
        this.busyContracts = ans; // Assign contracts to data property
        console.log("busyContracts length : ", this.busyContracts.length);
        this.$refs.contractModal.show(); // Show modal

      }
    },
    sendCheckInDetailsButtonSMS(id) {
      this.sendSMSCheckIn(id);
    },
    getProgressValue(contract) {
      var sum = 100;

      let isSignedValue = contract.signedOffer ? 0 : 25;

      if (contract.alerts == null || contract.alerts.length === 0)
        return 100 - isSignedValue;

      for (let i = 0; i < contract.alerts.length; i++) {
        if (contract.alerts[i].actionRequired === "RENT_PAYMENT") {
          sum -= 25;
        } else if (contract.alerts[i].actionRequired === "AUTHORIZATION_FEE_PAYMENT") {
          sum -= 25;
        } else if (contract.alerts[i].actionRequired === "UPLOAD_REQUIRED_FILES") {
          sum -= 25;
        }
      }
      return sum - isSignedValue;


    },
  }
}
</script>

<style lang="scss">
.real-estate-contracts {
  .progress-bar span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  /* Custom CSS for gradient background */
  .bg-gradient {
    background: linear-gradient(to right, #28a745, #dc3545);
  }

  .table-filter.sticky {
    position: sticky;
    z-index: 999;
    top: 80px;
    padding: 0 0.5rem;
  }
}
</style>
